import {DataService} from "../../../../core/service/data.service";
import {Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";

@Injectable()
export class OrdemServicoHistoricoService {

    public colunas = {
        OrdemServicoHistorico: ['*'],
        OrdemServico: ['id'],
        OrdemServicoHistoricoUsuario: ['nome'],
    };
    public servico = 'ordem_servico_historico';
    public url: string = '/servico/ordem-servico-historico';

    constructor(
        public dataService: DataService,
        public requisicao: RequisicaoService,
    ) {

    }

    get(id) {
        return this.requisicao.get({
            url: `${this.url}/${id}`,
            data: {
                colunas: this.colunas
            }
        });
    }

}