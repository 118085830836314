import {Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";

@Injectable()
export class SetorMaquinaService{
    public url : string = '/industrializacao/setor/maquina';
    public servico = 'setor_maquina';

    constructor(public requisicao : RequisicaoService){
        
    }
    
    salvar(dadosFormulario){
        return this.requisicao.post({
            url     : this.url,
            campos  : dadosFormulario
        });
    }
    
    editar(dadosFormulario, id){
        return this.requisicao.patch({
            url     : `${this.url}/${id}`,
            campos  : dadosFormulario
        });
    }
    
    get(id){
        return this.requisicao.get({
            url     : `${this.url}/${id}`,
        });
    }


    excluir(id){
        return this.requisicao.delete({
            url     : `${this.url}/${id}`,
        });
    }
    
    formataFormulario(dadosFormulario){
        if(!dadosFormulario.hasOwnProperty('ativo')){
            dadosFormulario.ativo = true;
        }
        return [
            {nome: 'nome', valor: dadosFormulario.nome, descricao: 'Nome'},
            {nome: 'observacao', valor: dadosFormulario.observacao, descricao: 'Observacao'},
            {nome: 'maquina', valor: (dadosFormulario.maquina)? dadosFormulario.maquina.id : null, descricao: 'Máquina'},
            {nome: 'maquinaHidden', valor: (dadosFormulario.maquinaHidden)? dadosFormulario.maquinaHidden.id : null, descricao: 'Máquina'},
            {nome: 'setor', valor: (dadosFormulario.setor)? dadosFormulario.setor.id : null, descricao: 'Setor'},
            {nome: 'ativo', valor: dadosFormulario.ativo, descricao: 'Ativo'},
        ];
    }


} 