import {Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";

@Injectable()
export class ProcessoTipoLucroService{
    public itemEditar : any = null;
    public url : string = '/comex/processo-tipo-lucro';

    constructor(public requisicao : RequisicaoService){
        
    }
    
    salvar(dadosFormulario){
        return this.requisicao.post({
            url     : this.url,
            campos  : dadosFormulario
        });
    }
    
    editar(dadosFormulario, id){
        return this.requisicao.patch({
            url     : `${this.url}/${id}`,
            campos  : dadosFormulario
        });
    }
    
    get(id){
        return this.requisicao.get({
            url     : `${this.url}/${id}`,
        });
    }


    excluir(id){
        return this.requisicao.delete({
            url     : `${this.url}/${id}`,
        });
    }
    
    formataFormulario(dadosFormulario){
        return [
            {nome: 'nome' , valor: dadosFormulario.nome, descricao: 'Nome'}];
    }


} 