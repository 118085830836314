import {Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";

@Injectable()
export class ExercicioFiscalRegraRegimeService{
    public itemEditar : any = null;
    public url : string = '/faturamento/exercicio-fiscal-regra-regime';


    constructor(public requisicao : RequisicaoService){
        
    }
    
    salvar(dadosFormulario){
        return this.requisicao.post({
            url     : this.url,
            campos  : dadosFormulario
        });
    }
    
    editar(dadosFormulario, id){
        return this.requisicao.patch({
            url     : `${this.url}/${id}`,
            campos  : dadosFormulario
        });
    }
    
    get(id){
        return this.requisicao.get({
            url     : `${this.url}/${id}`,
        });
    }


    excluir(id){
        return this.requisicao.delete({
            url     : `${this.url}/${id}`,
        });
    }
    
    formataFormulario(dadosFormulario){
        return [
            {nome: 'nome'         , valor: dadosFormulario.nome, descricao: 'Nome'},
            {nome: 'niverDia'     , valor: dadosFormulario.niverDia, descricao: 'Dia Niver.'},
            {nome: 'niverMes'     , valor: dadosFormulario.niverMes, descricao: 'Msê Niver.'},
            {nome: 'socio'        , valor: dadosFormulario.socio, descricao: 'Socio'},
            {nome: 'master'       , valor: dadosFormulario.master, descricao: 'Master'},
            {nome: 'sexo'         , valor: dadosFormulario.sexo, descricao: 'Gênero'},
            {nome: 'telefone'     , valor: dadosFormulario.telefone, descricao: 'Telefone'},
            {nome: 'celular'      , valor: dadosFormulario.celular, descricao: 'Celular'},
            {nome: 'email'        , valor: dadosFormulario.email, descricao: 'E-mail'},
            {nome: 'setor'        , valor: dadosFormulario.setor, descricao: 'Setor'},
            {nome: 'timefutebol'  , valor: (dadosFormulario.timefutebol)?dadosFormulario.timefutebol.id : null, descricao: 'Time de Futebol'},
            {nome: 'interesses'    , valor: (dadosFormulario.interesses)?dadosFormulario.interesses.join(', '): null, descricao: 'Interesses'},
            {nome: 'observacao'   , valor: dadosFormulario.observacao, descricao: 'Obervação'},
            {nome: 'cliente'      , valor: (dadosFormulario.cliente) ? dadosFormulario.cliente.id : null , descricao: 'Cliente'},
            {nome: 'pasta'      , valor: (dadosFormulario.pasta) ? dadosFormulario.pasta.id : null , descricao: 'pasta'},
        ];
    }


    joinSetorContato(setor){
        if(setor){
            return setor.map(function(elem){
                return elem.nome;
            }).join(", ");
        }
    }

} 