import {DataService} from "../../../../core/service/data.service";
import {Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";

@Injectable()
export class OrdemServicoComissionamentoService {

    public colunas = {
        OrdemServicoComissionamento: ['*'],
        OrdemServicoComissionamentoPessoa: ['id', 'nomerazao'],
        OrdemServico: ['id'],
    };
    public servico = 'ordem_servico_comissionamento';
    public url: string = '/servico/ordem-servico-comissionamento';

    constructor(
        public dataService: DataService,
        public requisicao: RequisicaoService,
    ) {

    }

    editar(dadosFormulario, id) {
        return this.requisicao.patch({
            url: `${this.url}/${id}`,
            campos: dadosFormulario,
            urlParametros: {
                colunas: this.colunas
            }
        });
    }

    excluir(id) {
        return this.requisicao.delete({
            url: `${this.url}/${id}`,
        });
    }

    formataFormulario(dadosFormulario) {

        return [
            {
                nome: 'pessoa',
                valor: (dadosFormulario.pessoa) ? dadosFormulario.pessoa.id : null,
                descricao: 'Indicador de Negócio / Representante / Responsável Técnico'
            },
            {
                nome: 'valorBase',
                valor: dadosFormulario.valorBase,
                descricao: 'Valor Base'
            },
            {
                nome: 'percentual',
                valor: dadosFormulario.percentual,
                descricao: 'Percentual (%)'
            },
            {
                nome: 'valorTotal',
                valor: dadosFormulario.valorTotal,
                descricao: 'Valor Total'
            },
            {
                nome: 'dataComissao',
                valor: this.dataService.getDataHora(dadosFormulario.dataComissao),
                descricao: 'Data de Comissão'
            },
            {
                nome: 'dataPrevisaoPagamento',
                valor: this.dataService.getDataHora(dadosFormulario.dataPrevisaoPagamento),
                descricao: 'Data de Previsão de Pagamento'
            },
            {
                nome: 'ordemServico',
                valor: dadosFormulario.ordemServico
            },
        ];
    }

    get(id) {
        return this.requisicao.get({
            url: `${this.url}/${id}`,
            data: {
                colunas: this.colunas
            }
        });
    }

    salvar(dadosFormulario) {
        return this.requisicao.post({
            url: this.url,
            urlParametros: {
                colunas: this.colunas
            },
            campos: dadosFormulario
        });
    }

}