import {EventEmitter, Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";

@Injectable()
export class UsuarioService {
    public itemEditar : any = null;
    public url : string = '/base/usuario';
    novoCadastro = new EventEmitter<any>();


    constructor(public requisicao : RequisicaoService){
        
    }
    
    salvar(dadosFormulario){
        return this.requisicao.post({
            url     : this.url,
            campos  : dadosFormulario
        });
    }
    
    editar(dadosFormulario, id){
        return this.requisicao.patch({
            url     : `${this.url}/${id}`,
            campos  : dadosFormulario
        });
    }
    
    get(id){
        return this.requisicao.get({
            url     : `${this.url}/${id}`,
        });
    }

    getAll(){
        return this.requisicao.get({
            url     : `${this.url}`,
            data : {
                quantidade_itens:999999999999999999
            }
        });
    }

    editarAssinatura(id, assinatura){
        return this.requisicao.patch({
            url     : `${this.url}/${id}`,
            campos  : [
                {nome: 'assinatura', valor: assinatura, descricao: 'assinatura'}
            ]
        });
    }
    
    excluir(id){
        return this.requisicao.delete({
            url     : `${this.url}/${id}`,
        });
    }
    
    formataFormulario(dadosFormulario){
        return [
            {nome: 'nome'           , valor: dadosFormulario.nome           , descricao: 'Nome'},
            {nome: 'email'          , valor: dadosFormulario.email          , descricao: 'E-mail'},
            {nome: 'senha'          , valor: dadosFormulario.senha          , descricao: 'Senha'},
            {nome: 'confirmar-senha', valor: dadosFormulario.confirmarSenha , descricao: 'Confirmar Senha'},
            {nome: 'perfil'         , valor: (dadosFormulario.perfil) ? dadosFormulario.perfil.id : null, descricao: 'perfil'},
            {nome: 'email-hidden'   , valor: dadosFormulario.hiddenEmail    , descricao: ''},
            {nome: 'pasta'          , valor: (dadosFormulario.pasta) ? dadosFormulario.pasta.id : null, descricao: 'Pasta'},
        ];
    }
} 