import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {SelectModule as CoreSelectModule} from "../../../../core/select/select.module";
import {SelectComponent} from "./select.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreSelectModule,

  ],
  exports: [
    SelectComponent
  ],
  declarations: [
    SelectComponent
  ],
})
export class SelectModule { }

