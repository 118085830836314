import {Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";

@Injectable()
export class PerfilService {
    public itemEditar : any = null;
    public url : string = '/base/perfil';

    constructor(public requisicao : RequisicaoService){
        
    }
    
    salvar(dadosFormulario){
        return this.requisicao.post({
            url     : this.url,
            campos  : dadosFormulario
        });
    }
    
    editar(dadosFormulario, id){
        return this.requisicao.patch({
            url     : `${this.url}/${id}`,
            campos  : dadosFormulario
        });
    }
    
    get(id){
        return this.requisicao.get({
            url     : `${this.url}/${id}`,
        });
    }
    
    excluir(id){
        return this.requisicao.delete({
            url     : `${this.url}/${id}`,
        });
    }
    getAllScopes(){
        return this.requisicao.get({
            url     : `/base/permissoes`,
            data: {
                order: {'oauthScopesGrupoNome': 'asc', 'nome': 'asc'},
                quantidade_itens: 999999999999999
            }
        });
    }



    formataFormulario(dadosFormulario){
        return [
            {nome: 'nome'           , valor: dadosFormulario.nome           , descricao: 'Nome'},
            {nome: 'scopes[]'       , valor: this.getidScopes(dadosFormulario.scopes), descricao: 'Permissões'},
         
        ];
    }

    getidScopes(scopes){
        let array = [];
        if(scopes && scopes.length){
            for(let item of scopes){
                array.push(item.id);
            }
        }
        return array;
    }
} 