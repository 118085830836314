import {DataService} from "../../../../core/service/data.service";
import {Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";

@Injectable()
export class ComissionamentoService{

    public colunas = {
        OrcamentoPedido : ['*'],
        Pessoa : ['id', 'nomerazao'],
        PessoaCliente : ['id'],
        Representante : ['id'],
        RepresentantePessoa : ['id', 'nomerazao'],
        IndicadorNegocio : ['id'],
        IndicadorNegocioPessoa : ['id', 'nomerazao'],
        CondicaoPagamento : ['id', 'nome'],
        OrdemServico : ['id'],
        OrdemServicoStatus : ['id', 'nome'],
        OrdemServicoTipo : ['id', 'nome'],
        OrdemServicoCliente : ['id'],
        OrdemServicoClientePessoa : ['nomerazao'],
        OrcamentoPedidoProduto : ['*'],
        OrcamentoPedidoProdutoProduto : ['id', 'nome', 'tipo'],
        OrcamentoPedidoProdutoProdutosku : ['id', 'descricao'],
        OrcamentoPedidoProdutoOrcamentoPedidoProdutoKit : ['id'],
        OrcamentoPedidoProdutoProdutoPasta : ['id'],
        OrcamentoPedidoProdutoProdutoPastaArquivos : ['hash'],
        OrcamentoPedidoProdutoKit : ['*'],
        OrcamentoPedidoProdutoKitProdutoKit : ['*'],
        OrcamentoPedidoProdutoKitProdutoKitPasta : ['id'],
        OrcamentoPedidoProdutoKitProdutoKitPastaArquivo : ['hash'],
        OrcamentoPedidoProdutoKitProdutoKitProduto : ['*'],
        OrcamentoPedidoProdutoKitProdutoKitProdutoProduto : ['id', 'nome'],
        OrcamentoPedidoProdutoKitProdutoKitProdutoProdutosku : ['id', 'descricao'],
    };
    public itemEditar : any = null;
    public ordemServico : any = null;
    public servico = 'orcamento_pedido';
    public url : string = '/servico/orcamento-pedido';

    constructor(
        public dataService: DataService,
        public requisicao : RequisicaoService,
    ){

        this.inicializaOrdemServico();

    }

    cancelar(id){
        return this.requisicao.get({
            url     : `${this.url}/cancelar/${id}`,
        });
    }

    desvincular(id){
        return this.requisicao.get({
            url     : `${this.url}/desvincular/${id}`,
        });
    }

    editar(dadosFormulario, id) {
        return this.requisicao.patch({
            url: `${this.url}/${id}`,
            campos: dadosFormulario,
            urlParametros: {
                colunas: this.colunas
            }
        });
    }

    excluir(id){
        return this.requisicao.delete({
            url     : `${this.url}/${id}`,
        });
    }

    formataFormulario(dadosFormulario){

        return [
            {
                nome: 'status',
                valor: dadosFormulario.status
            },
            {
                nome: 'tipo',
                valor: dadosFormulario.tipo
            },
            {
                nome: 'pessoa',
                valor: dadosFormulario.pessoa ? dadosFormulario.pessoa.id : null,
                descricao: 'Cliente / Prospect'
            },
            {
                nome: 'condicaopagamento',
                valor: dadosFormulario.condicaopagamento ? dadosFormulario.condicaopagamento.id : null
            },
            {
                nome: 'representante',
                valor: dadosFormulario.representante ? dadosFormulario.representante.id : null,
                descricao: 'Representante',
            },
            {
                nome: 'indicadorNegocio',
                valor: dadosFormulario.indicadorNegocio? dadosFormulario.indicadorNegocio.id : null,
                descricao: 'Representante',
            },
            {
                nome: 'ordemServico',
                valor: dadosFormulario.ordemServico ? dadosFormulario.ordemServico.id : null
            },
            {
                nome: 'dataPrevisaoIni',
                valor: this.dataService.getDataHora(dadosFormulario.dataPrevisaoIni),
                descricao: 'Previsão do Início'
            },
            {
                nome: 'dataPrevisaoFim',
                valor: this.dataService.getDataHora(dadosFormulario.dataPrevisaoFim),
                descricao: 'Previsão do Fim'
            },
            {
                nome: 'anotacoes',
                valor: dadosFormulario.anotacoes
            },
            {
                nome: 'clienteProspectsObs',
                valor: dadosFormulario.clienteProspectsObs
            },
            {
                nome: 'produtos',
                valor: dadosFormulario.produtos
            },
            {
                nome: 'servicos',
                valor: dadosFormulario.servicos
            },
            {
                nome: 'kits',
                valor: dadosFormulario.kits
            },
            {
                nome: 'valorTotal',
                valor: dadosFormulario.valorTotal
            },
        ];
    }

    get(id) {
        return this.requisicao.get({
            url: `${this.url}/${id}`,
            data: {
                colunas: this.colunas
            }
        });
    }

    inicializaOrdemServico(){
        this.ordemServico = {
            id : null,
            pessoa: null,
            ordemServicoStatus : null,
            ordemServicoTipo : null,
        };
    }

    salvar(dadosFormulario){
        return this.requisicao.post({
            url     : this.url,
            urlParametros: {
                colunas : this.colunas
            },
            campos  : dadosFormulario
        });
    }

} 