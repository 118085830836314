import {Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";
import {Service} from "../../../../core/crud/service/service";

@Injectable()
export class OrdemServicoMotivoEncerramentoService extends Service {

    public servico = 'ordem_servico_motivo_encerramento';

    constructor(public requisicao : RequisicaoService){
        super(requisicao);
        this.url = '/servico/ordem-servico-motivo-encerramento';
        this.listaLinksExcluir = [
            {apelido: 'Ordem de Serviço', link: 'servico/ordem-servico/editar/{{id}}'}
        ]
    }
    
    editar(dadosFormulario, id){
        return this.requisicao.patch({
            url     : `${this.url}/${id}`,
            campos  : dadosFormulario
        });
    }
    
    excluir(id){
        return this.requisicao.delete({
            url     : `${this.url}/${id}`,
        });
    }
    
    formataFormulario(dadosFormulario){
        return [
            {nome: 'motivo', valor: dadosFormulario.motivo, descricao: 'Motivo'},
            {nome: 'ativo', valor: dadosFormulario.ativo ? 1 : 0, descricao: 'Motivo de Encerramento'},
        ];
    }

    get(id){
        return this.requisicao.get({
            url     : `${this.url}/${id}`,
        });
    }

    salvar(dadosFormulario){
        return this.requisicao.post({
            url     : this.url,
            campos  : dadosFormulario
        });
    }
} 