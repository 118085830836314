import {Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";
import {Service} from "../../../../core/crud/service/service";
import {DataService} from "../../../../core/service/data.service";

@Injectable()
export class EstoqueAtendimentoService extends Service {

    public servico = 'estoque_atendimento';
    public colunas = {
        EstoqueAtendimento: ['*'],
        EstoqueAtendimentoProdutos: ['*'],
        EstoqueAtendimentoProdutosProduto: ['*'],
        EstoqueAtendimentoProdutosProdutosku: ['*'],
        EstoqueAtendimentoResponsavelTecnico: ['*'],
        EstoqueAtendimentoResponsavelTecnicoPessoa: ['*'],
        EstoqueAtendimentoProdutosEstoqueAtendimentoSaida : ['id'],
    };

    constructor(
        public dataService: DataService,
        public requisicao : RequisicaoService,
    ){
        super(requisicao);
        this.url = '/servico/estoque-atendimento';
    }

    consultaSaida(url, obj?){
        return this.requisicao.get({
            url : url,
            data: obj,
        });
    }

    editar(dadosFormulario, id) {
        return this.requisicao.patch({
            url: `${this.url}/${id}`,
            campos: dadosFormulario,
            urlParametros: {
                colunas: this.colunas
            }
        });
    }
    
    excluir(id){
        return this.requisicao.delete({
            url     : `${this.url}/${id}`,
        });
    }
    
    formataFormulario(dadosFormulario){
        return [
            {
                descricao: 'Tipo',
                nome: 'tipo',
                valor: dadosFormulario.tipo,
            },
            {
                descricao: 'Responsável Técnico',
                nome: 'responsavelTecnico',
                valor: dadosFormulario.responsavelTecnico,
            },
            {
                nome: 'estoqueAtendimentoSaida',
                valor: dadosFormulario.estoqueAtendimentoSaida,
            },
            {
                descricao: 'Produtos',
                nome: 'produtos',
                valor: dadosFormulario.produtos,
            },
            {
                descricao: 'Observação',
                nome: 'observacao',
                valor: dadosFormulario.observacao,
            },
            {
                nome: 'dataCadastro',
                valor: this.dataService.getDataHora(dadosFormulario.dataCadastro),
                descricao: 'Data Saída'
            },
        ];
    }

    get(id) {
        return this.requisicao.get({
            url: `${this.url}/${id}`,
            data: {
                colunas: this.colunas
            }
        });
    }

    salvar(dadosFormulario){
        return this.requisicao.post({
            url     : this.url,
            urlParametros: {
                colunas : this.colunas
            },
            campos  : dadosFormulario
        });
    }
} 