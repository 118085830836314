import {Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";

@Injectable()
export class AgendamentoAtendimentoService {

    public colunas = {
        OrdemServicoAtendimento: ['*'],
        OrdemServico: ['id'],
        OrdemServicoCliente: ['id'],
        OrdemServicoClientePessoa: ['nomerazao'],
        OrdemServicoAtendimentoResponsavelTecnico: ['id'],
        OrdemServicoAtendimentoResponsavelTecnicoPessoa: ['id', 'nomerazao'],
        OrdemServicoAtendimentoPasta: ['id'],
        OrdemServicoAtendimentoPastaArquivos: ['hash'],
        OrdemServicoAtendimentoProduto: ['*'],
        OrdemServicoAtendimentoProdutoProduto: ['id', 'nome', 'tipo'],
        OrdemServicoAtendimentoProdutoProdutosku: ['id', 'descricao'],
    };
    public servico = 'ordem_servico_atendimento';
    public url: string = '/servico/ordem-servico-atendimento';

    constructor(
        public requisicao: RequisicaoService,
    ) {

    }

    get(filtros){
        return this.requisicao.get({
            url : this.url,
            data: {
                quantidade_itens: 99999999999999,
                filtros: filtros,
                colunas: this.colunas,
                order: {OrdemServicoAtendimento : {dataAgendamento : 'asc'}}
            }
        });
    }

}