import {Component, forwardRef, Input, NgZone, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {FuncionarioService} from "../service/funcionario.service";

@Component({
    selector: 'admin-rh-funcionario-select',
    templateUrl: './select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ],
})
export class SelectComponent implements ControlValueAccessor, OnInit{
    valor           : any;
    private _value  :any;

    @Input() multiple = false;
    @Input() disabled = false;
    @Input() name       = '';
    @Input() ordem  = {'Pessoa': {nomerazao: 'asc'}};
    @Input() colunas  = {'Pessoa': ['nomerazao']};
    get value(): any { return this._value; };
    @Input() set value(v) {
        if (v !== this._value) {
            this._value = v;
            this._onChangeCallback(v);
        }
    }

    constructor(
        public zone: NgZone,
        public service: FuncionarioService
    ){

    }
    ngOnInit(){
        if(this.multiple && !Array.isArray(this.valor )){
            this.valor = [];
        }
    }


    writeValue(value: any): void {
        this._value = value;
        this.valor = value;
    }
    updateValue (value: any) {
        this.zone.run(() => {
            this._value = value;
            this.valor = value;
            this.onChange(value);
            this._onTouchedCallback();
        });
    }


    onChange (_: any) {}
    onTouched () {}
    registerOnChange (fn: any) { this.onChange = fn; }
    registerOnTouched (fn: any) { this.onTouched = fn; }
    _onChangeCallback (_: any) {}
    _onTouchedCallback () {}
}